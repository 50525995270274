
.estilos-h1 {
    font-family: 'Italianno';
    font-size: 79px;
    font-style: italic;
    font-weight: 600;
    line-height: 60px;
    margin-left: 20px;
    text-transform: capitalize;
}

.estilos-h1 span {
  font-size: 45px;
}

.estilos-h2{
  font-family: 'Italianno';
    font-size: 60px;
    font-weight: 600;
}

.estilos-nav {
  font-family: 'Agrandir';
  font-size: large;
  font-weight: bold;
}

.footer-h3{
  font-family: 'Agrandir';
  font-weight: bolder;
  font-size: 30px;
}

.estilos-h3{
  font-family: 'Italianno';
  font-size: 45px;
  font-weight: 300;
  line-height: 50xp;
}

.estilos-h5{
  font-family: 'Agrandir';
  font-size: 33px;
  font-weight: 300;
}

.seccion-p{
  font-family: Arial, Helvetica, sans-serif;
  font-size: 23px;
}

.estilos-card{
  font-family: 'Agrandir';
  font-size: 31px;
  font-weight: 300;
}

.card-p{
  font-family: 'Agrandir';
  font-size: 20px;
  font-weight: 100;
}

.card_title{
  translate: "no";
}
  /* scroll to Top*/
  .top-to-btm{
    position: relative;
  }
  .icon-position{
    position: fixed;
    bottom: 8px;
    right: 27px;
    z-index: 20;
  }
  .icon-style{
    background-color: #0D111C;
    border: 1px solid #fff;
    border-radius: 50%;
    height: 37px;
    width: 37px;
    color: #fff;
    cursor: pointer;
    animation: movebtn 3s ease-in-out infinite;
    transition: all .5s ease-in-out;
  }
  .icon-style:hover{
    animation: none;
    background: #fff;
    color: #0D111C;
    border: 1px solid #0D111C;
  }


  /* for desktop wathsaap*/
    .whatsapp_float {
      position: fixed;
      width: 61px;
      height: 61px;
      bottom: 60px;
      right: 16px;
      background-color: #41bc6e;
      color: #FFF;
      border-radius: 23px;
      text-align: center;
      font-size: 44px;
      z-index: 100;
  }
  
  .whatsapp_float:hover {
    color: #0D111C;
    transform: translateY(-1px);
    transition: all .5s ease-in-out;
  }
  

   /* for mobile */
  @media screen and (max-width: 767px) {
    
      .whatsapp_float {
          width: 50px;
          height: 50px;
          bottom: 10px;
          right: 20px;
          font-size: 36px;
          padding-bottom: 7px;
      }

      .estilos-h1 {
        font-size: 55px;
        line-height: 43px;
        margin-top: 30px;
    }

    .estilos-h1 span{
      font-size: 40px;
    }

    .estilos-h2{
        font-size: 50px;
        font-weight: 500;
    }

    .estilos-h3{
      font-size: 35px;
    }
  }
